























































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { LoginService } from '@services/login.service';
import { LSS } from '../core/services';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config'
import { decodeToken } from '@shared/utils/commom.util'
import { SignUpService } from '@services/signup.service';

export default Vue.extend({
  name: 'OnboardFork',
  data() {
    return {
      msgModel: false,
      disableModel: false,
      message: '',
      msgTitle: '',
      verifyMessage: '',
      verifyTitle: 'Verify Email',
      verifyModel: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      verifyEmail: '',
      infoModel: false,
      infoTitle: 'Success',
      infoMessage: 'Please check your mail. Redirecting to login',
    };
  },
  components: {  },
  methods: {
    saveSessionLSS(session, user) {
      LSS.sessId = session.id;
      this.isOnBoarded(user);
    },
    getSession() {
      FlowService.whoIamI()
        .then(async (res) => {
          const user = await this.getUserDetails(res.identity.id, res.id);
          this.saveSessionLSS(res,user);
        })
        .catch((err) => console.log(err));
    },
    okAction() {
      window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
    },
    verifyAction() {
      //return this.$router.push('/verify');
      //window.location.href = API_ORY_URL + 'self-service/browser/flows/logout?return_to='+API_ORY_URL+'self-service/verification/browser'
      this.msgModel = false;
      this.verifyModel = true;
      this.verifyTitle = 'Verify Email',
      this.verifyMessage = 'Please enter your E-mail to send verification link';
      return;
    },
    verifyOk() {
      this.sendMail(this.verifyEmail);
    },
    verifyCancel() {
      window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
    },
    isOnBoarded(user) {
      if (!user.IsVerified) {
        this.msgModel = true;
        this.msgTitle = 'Info!!!';
        this.message = 'Please verify your E-mail';
        return;
      }
      if (user.IsDisabled) {
        this.disableModel = true;
        this.msgTitle = 'Info!!!';
        this.message = 'Your Account is Disabled';
        return
      }
      if (user.IsOnboarded) {
        return this.$router.push('/overview');
      }
      return this.$router.push('/onboard');
    },
    async getUserDetails(UserID: string, SessID: string) {
      const token = await LoginService.getUserDetails({
      UserID: UserID,
      SessId: SessID
    });
      const user = decodeToken(token);
      //console.log(user);
      if(!user){
        console.log('Ory DB should be cleaned');
        LSS.clear();
        window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
      }
      LSS.token = token
      this.$root.$emit('userNameFetched', user);
      return user;
    },
    async sendMail(email){
      const mail = await SignUpService.sendVerifyLink({
        email: email,
      });
      if(mail == 'success'){
        this.verifyModel = false;
        this.infoModel = true;
        setTimeout(() => {
          this.verifyModel = false;
          window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
        }, 1500)
        return;
      }else{
        this.infoModel = true;
        this.infoTitle = 'Oops'
        this.infoMessage = 'Something happened. Try again later'
      }
    }
  },
  mounted() {
    this.getSession();
  },
});
