import { API_BASE_URL } from '@config';
import { AUTH_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';
import { ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

/**
 * @class LoginService
 * @description service class for handling login process.
 */
export class LoginService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.signinOry);
  }

  /**
   * @function
   * @name signinOry
   * @description service function for signing in
   * @param {any} signInDetails
   * @returns {Promise<any>} response as a promise
   */
  public static async signinOry(signInDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url), signInDetails);
    return response;
  }

  /**
 * @function
 * @name isOnObaorded
 * @description service function for isOnObaorded
 * @param {any} userId
 * @returns {Promise<any>} response as a promise
 */
  public static async isOnObaorded(req: any): Promise<ISuccess> {
    const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.isOnBoarded);
    const response = await HttpService.post<ISuccess>(joinUrl(url), req);
    return response;
  }

  /**
 * @function
 * @name getUserDetails
 * @description get user details by user id
 * @param {string} UserID
 * @returns {Promise<any>} response as a promise
 */
  public static async getUserDetails(req: {
    UserID: string,
    SessId: string
  }): Promise<ISuccess> {
    //const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.getUserById, UserID);
    // console.log(UserID, SessID)
    // const url = joinUrl(API_BASE_URL as string, 'auth/', replaceParamsWithValues(AUTH_ROUTES.getUserById, UserID, SessID));
    // const response = await HttpService.get<any[]>(joinUrl(url));
    const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.getUserById);
    console.log(url)
    const response = await HttpService.post<ISuccess>(joinUrl(url), req);
    return response;
  }

  /**
   * @function
   * @name isInvited
   * @description to check user if invited or not
   * @param {string} email
   * @returns {Promise<any>} response as a promise
   */
  public static async isInvited(email: string): Promise<any[]> {
    //const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.getUserById, UserID);
    const url = joinUrl(API_BASE_URL as string, 'auth/', replaceParamsWithValues(AUTH_ROUTES.isInvited, email));
    const response = await HttpService.get<any[]>(joinUrl(url));
    return response;
  }
}
