var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.msgModel,
            callback: function($$v) {
              _vm.msgModel = $$v
            },
            expression: "msgModel"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(" " + _vm._s(_vm.msgTitle) + " ")
              ]),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.okAction }
                    },
                    [_vm._v(" Ok ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.verifyAction }
                    },
                    [_vm._v(" Re-Send Verification Link ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.disableModel,
            callback: function($$v) {
              _vm.disableModel = $$v
            },
            expression: "disableModel"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(" " + _vm._s(_vm.msgTitle) + " ")
              ]),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.okAction }
                    },
                    [_vm._v(" Ok ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.infoModel,
            callback: function($$v) {
              _vm.infoModel = $$v
            },
            expression: "infoModel"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(" " + _vm._s(_vm.infoTitle) + " ")
              ]),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.infoMessage) + " ")]),
              _c("v-divider")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.verifyModel,
            callback: function($$v) {
              _vm.verifyModel = $$v
            },
            expression: "verifyModel"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(" " + _vm._s(_vm.verifyTitle) + " ")
              ]),
              _c(
                "v-card-text",
                [
                  _c("label", { staticClass: "black--text mb-3 d-flex" }, [
                    _vm._v("Enter your email Address")
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      outlined: "",
                      dense: "",
                      rules: _vm.emailRules
                    },
                    model: {
                      value: _vm.verifyEmail,
                      callback: function($$v) {
                        _vm.verifyEmail = $$v
                      },
                      expression: "verifyEmail"
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: !/.+@.+\..+/.test(_vm.verifyEmail)
                      },
                      on: { click: _vm.verifyOk }
                    },
                    [_vm._v(" Ok ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.verifyCancel }
                    },
                    [_vm._v("Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }