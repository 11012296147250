import { API_BASE_URL } from '@config';
import { AUTH_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';
import { ISuccess } from '@shared/interfaces';
import { joinUrl } from '@shared/utils';

/**
 * @class SignUpService
 * @description service class for handling SignUp process.
 */
export class SignUpService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.signupOry);
  }

  private static get verify_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.vupdate);
  }

  private static get send_verify_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.vLink);
  }

  /**
   * @function
   * @name signupOry
   * @description service function for SignUp
   * @param {any} signUpDetails
   * @returns {Promise<any>} response as a promise
   */
  public static async signupOry(signUpDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url), signUpDetails);
    return response;
  }

  public static async updateVerified(vDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.verify_url), vDetails);
    return response;
  }

  public static async sendVerifyLink(vDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.send_verify_url), vDetails);
    return response;
  }
}
